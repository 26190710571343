"use client";

import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { FieldValues, UseFormReturn } from "react-hook-form";

import { Form } from "@/components/Form";
import { Typography } from "@/components/Typography";
import { Button } from "@/components/Button";
import { GoogleAuthButton } from "@/components/GoogleAuthButton";

import { registerValidation } from "@/utils/validators/register";

import { amplitudeTrackEvent } from "@/services/AmplitudeService/AmplitudeService";
import { getCookie } from "@/services/CookiesService";

import { useToast } from "@/hooks/useToast";
import { useRegister } from "@/hooks/auth/useRegister";

import { AmplitudeContext } from "@/contexts/AmplitudeContext/AmplitudeContext";

import { AMPLITUDE_EVENT_NAME, GTM_EVENT, GTM_EVENT_NAME } from "@/internals/constants/events";

import { RegisterFormProps } from "./types";
import { FormValidationMethods } from "@/components/Form/types";
import { QR_REFERENCE_COOKIE } from "@/containers/AuthContainer/types";

export default function Register({
  onSetFormType,
  onRegister,
  setIsLoading,
  isLoading,
  source,
  registerData,
}: RegisterFormProps) {
  const { toast } = useToast();
  const { t } = useTranslation();

  const amplitudeContext = useContext(AmplitudeContext);
  const { onHandleRegister, registerResponse, registerError } = useRegister();

  const [isGoogleLoginLoading, setIsGoogleLoginLoading] = useState(false);
  const [password, setPassword] = useState("");
  const [formObject, setFormObject] = useState<
    { values: FieldValues; methods: UseFormReturn } | undefined
  >(undefined);

  const schema = registerValidation(registerData, password);

  const {
    richtext: { Tag, content, className },
    button: { id, label },
  } = registerData.authModeSwitcher;

  const onRegisterSubmit = async (values: FieldValues, methods: UseFormReturn) => {
    setIsLoading(true);
    setFormObject({ values, methods });
    onHandleRegister({
      email: values.email,
      password: values.password,
      reference: getCookie(QR_REFERENCE_COOKIE),
    });
  };

  const onGoogleAuthSuccess = () => {
    window?.dataLayer?.push({
      event: GTM_EVENT_NAME.SIGN_UP_COMPLETED,
      source,
    } as GTM_EVENT);
    onRegister();
  };

  useEffect(() => {
    amplitudeContext.source &&
      amplitudeTrackEvent(AMPLITUDE_EVENT_NAME.view_landing_signup, {
        source: amplitudeContext.source,
      });
  }, []);

  useEffect(() => {
    if (!formObject) return;
    if (registerResponse?.status === 200 || registerResponse?.status === 201) {
      window?.dataLayer?.push({
        event: GTM_EVENT_NAME.SIGN_UP_COMPLETED,
        source,
      } as GTM_EVENT);
      setIsLoading(true);
      onRegister(formObject.values, formObject.methods);
    }
  }, [registerResponse]);

  useEffect(() => {
    if (!formObject) return;

    if (registerError?.status === 400 || registerError?.status === 401) {
      formObject.methods.setError("email", {
        message: registerData.invalidAuthMessage,
      });
      formObject.methods.resetField("password", { defaultValue: "" });
    } else {
      toast({ message: "genericError", type: "error" });
    }
    setIsLoading(false);
  }, [registerError]);

  const checkPassword = (value: string, methods: FormValidationMethods) => {
    setPassword(value);
    methods.trigger("password");
  };
  return (
    <div
      className="Register"
      data-testid="Register">
      <Typography
        Tag="p"
        classname="Register__title"
        size="xl"
        spacing={5}>
        {registerData.title}
      </Typography>
      <GoogleAuthButton
        type="signup"
        label="Continue with Google"
        isLoading={setIsGoogleLoginLoading}
        onSuccess={onGoogleAuthSuccess}
        isFormLoading={isLoading}
        errors={{
          registerError: registerData.invalidAuthGoogleMessage,
          genericError: t("_genericError"),
        }}
      />
      <Form
        fields={registerData.formFields}
        onSubmit={onRegisterSubmit}
        schema={schema}
        validationMode="onSubmit"
        isDisabled={isGoogleLoginLoading}
        isLoading={isLoading}
        onWatchFields={({ field, value, methods }) => {
          if (field === "password") checkPassword(value, methods);
        }}
        isAuth
      />

      <div className="Auth__switcher">
        <Typography
          Tag={Tag}
          spacing={2}
          classname={className}>
          {content}
        </Typography>
        <Button
          ghost
          spacing={2}
          buttonId={id}
          label={label}
          disabled={isGoogleLoginLoading}
          dataQA="register-modal-login-button"
          onClick={() => {
            amplitudeTrackEvent(AMPLITUDE_EVENT_NAME.view_landing_login, {
              source: "signup",
            });
            onSetFormType("login");
          }}
          className="switch-form-button"
        />
      </div>
      <div className="register-disclaimer">
        <span dangerouslySetInnerHTML={{ __html: registerData.disclaimer }}></span>
      </div>
    </div>
  );
}
