"use client";

import { useContext, useEffect, useState } from "react";
import { useRouter, useSearchParams } from "next/navigation";

import { Auth } from "@/components/Auth";
import { AuthHeader } from "./components/AuthHeader";
import { AuthCarousel } from "./components/AuthCarousel/AuthCarousel";

import { QREditorContext } from "@/contexts/QREditorContext";

import { getCookie, setCookie } from "@/services/CookiesService";
import { executePendingAction } from "@/services/PendingActionsService/PendingActionsService";

import { paths } from "@/utils/paths";

import { AuthContainerProps, AuthFormType, DOWNLOAD_QR_COOKIE, QR_REFERENCE_COOKIE } from "./types";
import { I_ForgotPasswordPage, I_RegisterPage, I_ResetPasswordPage } from "@/data/types/pages";

import "./styles.scss";

export const AuthContainer = <T extends AuthFormType>({
  formType,
  pageData,
  pendingAction,
}: AuthContainerProps<T>) => {
  const router = useRouter();

  const searchParams = useSearchParams();

  const [authFormType, setAuthFormType] = useState<AuthFormType>(formType || "login");
  const [isLoading, setIsLoading] = useState(false);

  const qrEditorContext = useContext(QREditorContext);

  useEffect(() => {
    const referenceCookie = getCookie(DOWNLOAD_QR_COOKIE);
    if (referenceCookie) {
      setCookie(QR_REFERENCE_COOKIE, referenceCookie.qrCodeReference);
    }
  }, []);

  useEffect(() => {
    if (authFormType) {
      switch (authFormType) {
        case "login":
          router.push(paths.login());
          break;
        case "register":
          router.push(paths.signup());
          break;
        case "forgotPassword":
          router.push(paths.forgotPassword());
          break;
        case "resetPassword": {
          const token = searchParams.get("token");
          if (token) {
            router.push(`${paths.resetPassword()}?token=${token}`);
          } else {
            router.push(paths.login());
          }
          break;
        }
        default:
          break;
      }
    }
  }, [authFormType]);

  const onActionSuccess = async () => {
    try {
      await executePendingAction(pendingAction, router, qrEditorContext);
      setIsLoading(false);
    } catch (error) {
      console.error(error);
      setIsLoading(false);
      throw new Error("Error executing pending action");
    }
  };

  return (
    <div className="auth__container">
      <div className="auth__container--form">
        <AuthHeader />
        <div className="auth__form">
          <Auth
            formType={formType}
            data={pageData}
            onChangeFormType={(formType) => setAuthFormType(formType)}
            onActionSuccess={onActionSuccess}
            setIsLoading={setIsLoading}
            isLoading={isLoading}
          />
        </div>
      </div>
      {pageData.page === "login" ? (
        <div className="auth__container__image-container">
          <img
            src="/images/login/login-image.png"
            alt="login"
          />
        </div>
      ) : (
        <div className="auth__container--carousel">
          <AuthCarousel
            data={pageData as I_RegisterPage | I_ForgotPasswordPage | I_ResetPasswordPage}
          />
        </div>
      )}
    </div>
  );
};
